.box-icons__items {
  border: 1px solid rgba(0, 0, 0, 0.221);
  border-radius: 13px;
  padding: 15px 15px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 13px;
  overflow-y: auto;
  max-height: 125px;
  svg {
    // color: $blue-dark-color;
    opacity: 0.7;
    &:hover {
      opacity: 1;
      color: rgb(33, 102, 161);
    }
  }
}
.box-icons__title {
  padding-bottom: 15px;
  // color: $blue-dark-color;
}
.box-icons__title-selected {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: #444;
  gap: 8px;
}
