.Backdrop__container {
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 30px 0;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
}
