.color_icon {
  & svg {
    color: red;
  }
}

.main_table {
  border-radius: 30px;
  padding: 10px;
}
