.boxLoading {
  padding: 26px;
  border-radius: 16px;
  min-width: 280px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 16px 1px #e9e9e9;
  flex-flow: column wrap;
  p {
    margin: 0 !important;
  }
}
